<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat>
        <v-toolbar-title>Document Scan Logs</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-spacer></v-spacer>

        <v-col cols="2">
          <v-btn icon class="ml-10" right @click="getDocData">
            <v-icon>mdi-sync</v-icon>
          </v-btn>
        </v-col>
      </v-toolbar>

      <v-card-text>
        <v-row>
          <v-col cols="10">
            <v-text-field
              v-model="search"
              append-icon="mdi-search"
              label="Search"
              single-line
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-btn
              small
              color="primary"
              class="ml-10 white --text"
              right
              @click="getDocData"
              >search
            </v-btn>
          </v-col>
        </v-row>
          <v-col cols="12" sm="12" md="12">
            <v-data-table
            dense
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="docData"
              :items-per-page="options.itemsPerPageOptions"
              :search="search"
              :options.sync="options"
              :server-items-length="totalItems"
              class="elevation-1"
              :server-search="true"
            >
              <template v-slot:item.created_at="{ item }">{{
                item.created_at | moment("LLL")
              }}</template>
              <!-- end of posting date template -->

              <template v-slot:item.syncStatus="{ item }">
                <v-btn
                  @click="openerrorDocDialog(item)"
                  color="red"
                  icon
                  v-show="item.withErrors == true"
                >
                  <v-icon>mdi-alert</v-icon>
                </v-btn>
                <v-btn color="green" icon v-show="item.withErrors == false">
                  <v-icon>mdi-check</v-icon>
                </v-btn>

                <v-btn color="grey" icon v-show="item.withErrors == 'upload'">
                  <v-icon>mdi-upload</v-icon>
                </v-btn>
              </template>

              <!-- action template -->
              <template v-slot:item.action="{ item }">
                <v-btn icon :to="`/gpm-scan-logs/${item.id}`">
                  <v-icon color="green">mdi-eye</v-icon>
                </v-btn>
              </template>
              <!-- end of action template -->
            </v-data-table>
          </v-col>
        <snackbar ref="snackbar"></snackbar>
      </v-card-text>
    </v-card>

    <!-- category quick add dialog -->
    <v-dialog v-model="errorDocDialog" max-width="650px">
      <v-card>
        <v-toolbar dense color="red" dark>
          <v-toolbar-title>Sync Process Error Message</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.native="errorDocDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                This document was not sync to SAP due to below following error:
              </v-col>
              <v-col cols="12">
                <span>
                  {{ this.ErrorMessage }}
                </span>
              </v-col>

              <v-row>
                <v-col cols="6">
                  <v-btn color="green" dark @click="errorDocDialog = false"
                    >Close</v-btn
                  >
                </v-col>
              </v-row>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end of modal-->
  </v-container>
</template>

<script>
export default {
  data: () => ({
    search: "",
    docData: [],
    errorDocDialog: false,
    ErrorMessage: null,
    loading: false,
    headers: [
      { text: "", value: "syncStatus" },
      { text: "Gate", value: "gates.Name" },
      { text: "DocNum", value: "DocNum" },
      { text: "Created at", value: "created_at" },
      { text: "Scanned By", value: "creator.name" },
      { text: "Result", value: "state" },
      { text: "View More", value: "action" },
    ],
    options: {
      rowsPerPageOptions: [10, 20, 30],
      itemsPerPageOptions: [10, 20, 30],
      itemsPerPage: 50,
      page: 1,
    },
    totalItems: 0,
  }),
  // watch: {
  //   options: {
  //     handler() {
  //       this.getDocData();
  //     },
  //     deep: true,
  //   },
  //   search() {
  //     this.options.page = 1;
  //     if (this.search.length >= 3) {
  //       this.getDocData();
  //     }
  //     if (this.search.length == 0) {
  //       this.getDocData();
  //     }
  //   },
  // },
  methods: {
    getDocData() {
      const self = this;
      this.loading = true;
      this.docData = [];
      this.$store
        .dispatch(
          "get",
          `/web-gpm-scan-logs?page=${this.options.page}&per_page=${this.options.itemsPerPage}&search=${this.search}&email=robertkimaru1998@gmail.com`
        )
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.docData = res.ResponseData.data;
            self.options.page = res.ResponseData.current_page;
            self.options.itemsPerPage = res.ResponseData.per_page;
            self.totalItems = res.ResponseData.total;
            self.loading = false;
          }
            if (res.ResultCode == 1043) {
            this.$refs.snackbar.show(res.ResultDesc, "red");
            self.loading = false;
          }
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getDocData();
  },
};
</script>